.button {
  color: #ffffff;
  border: none;
  border-radius: 20px;
  height: 34px;
  transition: all 0.4s ease;
  letter-spacing: 0.5px;
  box-shadow: 0 3px 10px rgba(0, 151, 178, 0.3);
  background-image: linear-gradient(to right, #0097b2, #006a7d);

  &:hover {
    color: #0097b2 !important;
    background-color: #ffffff !important;
    transform: scale(1.03);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 151, 178, 0.5);
  }

  &:active {
    transform: scale(0.97);
  }

  @media (max-width: 768px) {
    transition: all 0s ease;

    &:hover {
      color: #ffffff !important;
      background-image: linear-gradient(to right, #0097b2, #006a7d) !important;
      transform: none;
    }

    &:focus {
      box-shadow: none;
    }

    &:active {
      transform: none;
    }
  }
}
