.customMessage {
  div {
    background-color: #424242 !important;
    color: #ffffff;
    letter-spacing: 0.5px;
    min-height: 24px;
    border-radius: 15px !important;
  }
}

.closeIcon {
  color: #ffffff;
  margin-left: 10px;
  font-size: 12px;
}
